/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use './styles-m3-theme.scss' as m3-theme;

@use "tailwindcss/base";
@use "tailwindcss/components";
@use "tailwindcss/utilities";
@import "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0";
@import "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap";

@include mat.elevation-classes();
@include mat.app-background();

$theme: m3-theme.$light-theme;

@layer base {
  /* Override default scroll width & look */
  ::-webkit-scrollbar {
    @apply w-1;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #cbcbcd;
    -webkit-box-shadow: inset 0 0 6px #cbcbcd;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #a1a1a4;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }

  .bg-tertiary-98 {
    background-color: mat.get-theme-color($theme, 'tertiary', 98) !important;
  }

  .bg-success {
    background-color: #92C25580;
  }

  .bg-warning {
    background-color: #F9B13180;
  }

  .bg-error {
    background-color: #E6310980;
  }

  .bg-badge-success {
    background-color: #92C25526;
  }

  .bg-badge-warning {
    background-color: #F9B13126;
  }

  .bg-badge-error {
    background-color: #E6310926;
  }

  .bg-badge-neutral {
    background-color: #0057FF26;
  }

  .text-success {
    color: #92C255;
  }

  .text-warning {
    color: #F9B131;
  }

  .text-error {
    color: #e63109;
  }

  .text-neutral {
    color: #0057FF;
  }
}

html {
  --mat-app-background-color: #FAFAFA;
  --mat-sidenav-content-background-color: var(--mat-app-background-color);
  --mat-paginator-container-background-color: var(--mat-app-background-color);
  @include mat.all-component-themes($theme);
}

.mat-toolbar {
  background-color: var(--mat-app-background-color) ! important;
}

@mixin button-variant($variant) {
  .#{$variant}-button {
    @include mat.button-color($theme, $color-variant: #{$variant});
  }
}

@include button-variant(primary);
@include button-variant(secondary);
@include button-variant(tertiary);
@include button-variant(error);

@mixin icon-variant($variant) {
  .#{$variant}-icon {
    @include mat.icon-color($theme, $color-variant: #{$variant});
  }
}

@include icon-variant(primary);
@include icon-variant(secondary);
@include icon-variant(tertiary);
@include icon-variant(error);

@mixin checkbox-variant($variant) {
  .#{$variant}-checkbox {
    @include mat.checkbox-color($theme, $color-variant: #{$variant});
  }
}

@include checkbox-variant(primary);
@include checkbox-variant(secondary);
@include checkbox-variant(tertiary);
@include checkbox-variant(error);

.toggle-group-small {

  mat-button-toggle {
    width: 44px;
  }

  mat-icon {
    width: 20px;
    height: 20px;
    font-size: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .mat-mdc-button-touch-target {
    width: 24px !important;
    height: 24px !important;
  }

  @include mat.button-toggle-density(-4);
}

.primary {
  background-color: mat.get-theme-color($theme, 'primary') !important;
  color: mat.get-theme-color($theme, 'on-primary') !important;
  --mat-select-enabled-arrow-color:  mat.get-theme-color($theme, 'on-primary');
}

.fg-error {
  color: mat.get-theme-color($theme, 'error') !important;
}

.fg-warning {
  color: #FF881B !important;
}

.fg-primary {
  color: mat.get-theme-color($theme, 'primary') !important;
}

.border-primary{
  border-color: mat.get-theme-color($theme, 'primary') !important;
}

.border-l-primary {
  border-left-color: mat.get-theme-color($theme, 'primary') !important;
}

.border-r-primary {
  border-right-color: mat.get-theme-color($theme, 'primary') !important;
}

.fg-primary-30 {
  color: mat.get-theme-color($theme, 'primary', 30) !important;
}

.bg-neutral-90 {
  background-color: mat.get-theme-color($theme, 'neutral', 90) !important;
}

.bg-scheme-surface {
  background: var(--Schemes-Surface, #F7FAFA) !important;
}

.surface {
  background-color: mat.get-theme-color($theme, 'surface') !important;
  color: mat.get-theme-color($theme, 'on-surface') !important;
}

.fg-on-surface-variant {
  color: mat.get-theme-color($theme, 'on-surface-variant') !important;
}

.border-outline-variant {
  border-color: mat.get-theme-color($theme, 'outline-variant') !important;
}


.horizontal-nav-list {
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-one-line-container-height: 36px;

  background-color: mat.get-theme-color($theme, 'neutral', 95) !important;

  .bg-nav-selected {
    background: mat.get-theme-color($theme, 'secondary-container') !important;
  }
}

.mat-large-tooltip {
  max-width: none;
  .mat-mdc-tooltip-surface {
    max-width: none;
  }
}

.mat-paginator-font-size {
  font-size: var(--mat-paginator-container-text-size);
}

.mat-select-right-align {
  text-align: right;
  .mat-mdc-select-arrow-wrapper {
    padding-left: 6px;
  }
}

.mat-fab-secondary {
  background-color: mat.get-theme-color($theme, 'secondary-container') !important;;
  color: mat.get-theme-color($theme, 'on-secondary-container') !important;
}

.m3-headline-small {
  font-family: var(--HeadlineSmallFont) !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 32px !important;
}

.m3-headline-large {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.m3-title-large {
  font-family: var(--TitleLargeFont);
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.m3-display-small {
  font-family: var(--DisplaySmallFont);
  font-size: 36px;
  font-weight: 400;
  line-height: 44px;
}

.m3-body-large {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  --mat-table-row-item-label-text-size: 16px;
  --mat-table-header-headline-size: 16px;
  --mat-paginator-container-text-size: 16px;
  --mat-paginator-select-trigger-text-size: 16px;
  --mat-paginator-container-text-size: 16px;

  --mat-table-row-item-label-text-line-height: 24px;
  --mat-table-header-headline-line-height: 24px;
  --mat-paginator-container-text-line-height: 24px;
  --mat-paginator-container-text-line-height: 24px;

}

.m3-body-medium {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  --mat-table-row-item-label-text-size: 14px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-line-height: 20px;
  --mat-table-row-item-label-text-line-height: 20px;
}

#sentry-feedback {
  --accent-background: #006972; // cannot be calculated
}

.mat-calendar-body {
  --mat-datepicker-calendar-date-in-range-state-background-color: #d4e6e8;
}

// FIXME : this is due to a bug in material 3, disable it as soon as possible
.mdc-switch--selected div.mdc-switch__handle-track {
  transform: translateX(0);
}

// Angular scroll issue with dialog content
// https://github.com/angular/components/issues/20344
.mdc-dialog__content {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
